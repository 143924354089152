.app {
	height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1dvh;
	align-items: center;
	overflow: hidden;
}

html,
body {
	background: #ebe0d0;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 14px;
	color: #000;
	margin: 0;
	padding: 0;
}

#app {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logos {
	height: 5dvh;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: 20px;
}

.logos a {
	height: min(100%, 40px);
}

.logos a img {
	height: 100%;
}

.mobile {
	height: 10dvh;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.mobile a {
	height: min(100%, 50px);
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 20px;
	padding: 5px 8px 5px 0;
    border: 2px solid black;
    border-radius: 10px;
}

.mobile a img {
	height: 100%;
}

.mobile>span {
	font-size: 20px;
	font-weight: bold;
}

.swiper {
	width: min(70dvw, 70dvh);
	max-height: 80dvh;
	aspect-ratio: 9/16;
}

.swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 18px;
	font-size: 22px;
	font-weight: bold;
	color: #fff;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-slide .last-slide {
	position: relative;	
}

.swiper-slide .last-slide a {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
	color: #000;
}

.swiper-slide {
	background-color: white;
}

